import { Routes } from '@angular/router';
import ROUTES from './routes.const';
import { LayoutComponent } from './Layout/layout.component';
import { ActivitiesComponent } from './Pages/activities/activities.component';
import { JobAuditComponent } from './Pages/activities/job-audit/job-audit.component';
import { InstallersComponent } from './Pages/installers/installers.component';
import { DashboardComponent } from './Pages/dashboard/dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { ProfileSettingsComponent } from './Pages/profile-settings/profile-settings.component';
import { ProjectDetailsComponent } from './Pages/project-management/project-details/project-details.component';
import { ProjectManagementComponent } from './Pages/project-management/project-management.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: ROUTES.DASHBOARD,
        component: DashboardComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.ACTIVITIES,
        component: ActivitiesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.ACTIVITIES_AUDIT,
        component: JobAuditComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.INSTALLERS,
        component: InstallersComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.PROFILE_SETTINGS,
        component: ProfileSettingsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.PROJECT_MANAGEMENT,
        component: ProjectManagementComponent,
        canActivate: [MsalGuard],
      },
      {
        path: ROUTES.PROJECT_DETAILS,
        component: ProjectDetailsComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
];
