<div class="dashboard-installer">
  <div class="coming-soon">
    <img src="assets/comingSoon.svg" alt="Coming Soon" />
    <p>We're working to bring this feature to you. Stay tuned!</p>
  </div>
  <!-- <p-table
    [columns]="cols"
    [value]="installersData"
    [paginator]="true"
    [rows]="5"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    dataKey="id"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>{{ rowData.name }}</td>
        <td style="color: #1950fd">{{ rowData.new }}</td>
        <td style="color: #741fb7">{{ rowData.assessments }}</td>
        <td style="color: #ef6500">{{ rowData.installation }}</td>
        <td style="color: #f500b0">{{ rowData.audit }}</td>
        <td style="color: #00ad00">{{ rowData.complete }}</td>
      </tr>
    </ng-template>
  </p-table> -->
</div>

<!-- <div class="dashboard-job">
  <p-table
    [columns]="jobCols"
    [value]="jobData"
    [paginator]="true"
    [rows]="5"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    dataKey="id"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>{{ rowData.name }}</td>
        <td>{{ rowData.location }}</td>
        <td style="width: 15%">
          <div [ngClass]="getStatusClass(rowData.status)">
            {{ rowData.status }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div> -->
