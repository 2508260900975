import ROUTES from './routes.const';

export const SideBarList = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    link: ROUTES.DASHBOARD,
    icon: '../assets/SidebarIcon/dashboard.svg',
  },
  {
    label: 'Activities',
    key: 'activities',
    link: ROUTES.ACTIVITIES,
    icon: '../assets/SidebarIcon/activities.svg',
  },
  {
    label: 'Installers',
    key: 'installers',
    link: ROUTES.INSTALLERS,
    icon: '../assets/SidebarIcon/Installers.svg',
  },
  {
    label: 'Projects',
    key: 'project-management',
    link: ROUTES.PROJECT_MANAGEMENT,
    icon: '../assets/SidebarIcon/Project.svg',
  },
];

export const ACTIVITIES_COLS = [
  { header: 'Activity' },
  { header: 'Installer' },
  { header: 'Date' },
  { header: 'Status' },
  { header: '' },
  { header: '' },
];

export const AUDIT_JOB_COLS = [
  { header: 'Name' },
  { header: 'Value' },
  { header: 'Reject' },
  { header: 'Pass' },
  { header: '' },
  { header: '' },
];

export const INSTALLER_COLS = [
  { header: 'Installer' },
  { header: 'Email' },
  { header: 'Status' },
  { header: '' },
];

export const DASHBOARD_TABLE = [
  { header: 'Name' },
  { header: 'New' },
  { header: 'Assessments' },
  { header: 'Installation' },
  { header: 'Audit' },
  { header: 'Complete' },
];

export const DASHBOARD_JOB_COLS = [
  { header: 'Job' },
  { header: 'Location' },
  { header: 'Status' },
];

export const USER_COLS = [
  { field: 'name', header: 'User' },
  { field: 'email', header: 'E-mail' },
  { field: 'status', header: 'Status' },
  { field: '', header: '' },
];

export const ACTIVITIES_USER_COLS = [
  { header: 'Scheme - Activity' },
  { header: '' },
  { header: '' },
];

export const JOB_COLS = [
  { header: 'Job' },
  { header: 'Location' },
  { header: 'Dwelling Type' },
  { header: 'Status' },
  { header: '' },
];

export const DWELLING_TYPE = [
  { label: 'Residential', value: 1 },
  { label: 'Commercial', value: 2 },
];

export const PROJECT_COLS = [
  { header: 'Project Name' },
  { header: 'Customer' },
  { header: 'Address' },
  { header: 'Status' },
  { header: '' },
];

export const PROJECT_TYPE = [
  { label: 'Commercial', value: 0 },
  { label: 'Residential', value: 1 },
];
