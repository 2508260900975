import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import ROUTES from '../../routes.const';
import { UserProfileService } from '../../Pages/profile-settings/user-profile.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [SharedModule],
  providers: [MessageService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  onHover: number = 0;
  profilePic: any;
  userName: string | null = '';

  constructor(
    private authService: MsalService,
    private router: Router,
    private service: UserProfileService,
    private messageService: MessageService
  ) {}

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit() {
    await this.getUserData();
    await this.getProfileImage();
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async handleProfileEdit(): Promise<void> {
    this.router.navigate([ROUTES.PROFILE_SETTINGS]);
  }

  async getUserData(): Promise<void> {
    try {
      const data = await this.service.fetchUserData();
      this.userName = data.name;
      if (data.status === 'Invited') {
        this.updateUserStatus();
      }
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  async updateUserStatus(): Promise<void> {
    try {
      const data = await this.service.updateUserStatus('ACTIVE');
    } catch (error: any) {}
  }

  private async getProfileImage(): Promise<any> {
    try {
      const response = await this.service.fetchUserProfilePic();
      const blob = new Blob([response], { type: 'image/jpeg' });
      this.profilePic = URL.createObjectURL(blob);
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  handleLogout(): void {
    this.authService.logoutRedirect();
  }
}
