import { Component } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { TableCols } from '../../types';
import { ACTIVITIES_COLS } from '../../constants';
import { ACTIVITIES_DATA } from '../../mock-data';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-activities',
  standalone: true,
  templateUrl: './activities.component.html',
  styleUrl: './activities.component.scss',
  imports: [SharedModule, SearchFieldComponent],
})
export class ActivitiesComponent {
  cols: TableCols[] = ACTIVITIES_COLS;
  activitiesList: any[] = ACTIVITIES_DATA;
  isLoading: boolean = false;
  skeletonRows = new Array(10);
  onHover: number = 0;

  constructor(private router: Router) {}

  handleSearch(val: string): void {}

  handleHover = (index: number) => {
    this.onHover = index;
  };

  activitiesDetails(rowData: any): void {
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`activities/${rowData.id}`], params);
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'New':
        return 'tag-new';
      case 'Audit':
        return 'tag-audit';
      case 'Completed':
        return 'tag-complete';
      case 'External Audit':
        return 'tag-external-audit';
      case 'Audit Complete':
        return 'tag-audit-complete';
      case 'Submitted':
        return 'tag-submitted';
      default:
        return '';
    }
  }
}
