const ROUTES = {
  DASHBOARD: '',
  ACTIVITIES: 'activities',
  ACTIVITIES_AUDIT: 'activities/:id',
  INSTALLERS: 'installers',
  PROFILE_SETTINGS: 'profile-settings',
  PROJECT_MANAGEMENT: 'project-management',
  PROJECT_DETAILS: 'project-management/:project',
};

export default ROUTES;
